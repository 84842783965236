<template>
	<div class="shipFormHeader puiformheader">
		<v-layout wrap>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.ship.imo')" :value="getImo"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.ship.name')" :value="getShipName"></pui-field>
			</v-flex>
			<v-flex xs12 md6 lg3>
				<pui-field :label="this.$t('header.ship.flag')" :value="getShipFlag"></pui-field>
			</v-flex>
		</v-layout>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'ship-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'ship'
		};
	},
	computed: {
		getImo() {
			return this.model && this.model.imo ? this.model.imo : '';
		},
		getShipName() {
			return this.model && this.model.name ? this.model.name : '';
		},
		getShipFlag() {
			return this.model && this.model.flag ? this.model.flag : '';
		}
	}
};
</script>
